import { createSlice } from "@reduxjs/toolkit";

export const feedbackSlice = createSlice({
  name: "feedbacks",
  initialState: {
    feedbacks: [],
  },
  reducers: {
    listFeedbackSuccess: (state, action) => {
      state.feedbacks = [...action?.payload?.feedbacks] || [];
      state.isNext = action?.payload?.isNext || false;
      state.isPrevious = action?.payload?.isPrevious || false;
    }
  }
});

export const {
  listFeedbackSuccess,
} = feedbackSlice.actions;

export default feedbackSlice.reducer;
