import { fetch, fetchAuth } from "../utils/request";
import dayjs from "dayjs";
import * as _ from "lodash";
export const loginAPI = async (values) => {
  const { data } = await fetch({
    url: "login",
    data: values,
    method: "post"
  });
  return data;
};

export const getMeAPI = async () => {
  const { data } = await fetchAuth({
    url: "me",
    method: "get"
  });
  return data;
};

export const listUserAPI = async (values) => {
  const _values = _.pickBy(values, _.identity);
  let _url = "users";
  Object.keys(_values).forEach((k, i) => {
    if (i === 0) {
      _url += `?${k}=${_values[k]}`;
    } else {
      _url += `&${k}=${_values[k]}`;
    }
  });
  const { data } = await fetchAuth({
    url: _url,
    method: "get"
  });
  return data;
};

export const getUserAPI = async (userId) => {
  const { data } = await fetchAuth({
    url: `users/${userId}`,
    method: "get"
  });
  return data;
};

export const getTaxYearsAPI = async (userId) => {
  const { data } = await fetchAuth({
    url: `tax-years/${userId}`,
    method: "get"
  });
  return data;
};

export const exportToDeviceAPI = async (values) => {
  const startTime = dayjs(values.startDate * 1000).format("YYYY-MM-DD");
  const endTime = dayjs(values.endDate * 1000).format("YYYY-MM-DD");
  const _objectBody = {
    startTime: startTime,
    endTime: endTime
  };
  if (values?.email) {
    _objectBody.emails = [values.email];
  }
  const { data } = await fetchAuth({
    url: `export-financial/${values.userId}`,
    method: "post",
    data: _objectBody
  });
  return data;
};

export const editUserAPI = async ({ userId, token, ...rest }) => {
  let values = {};
  Object.values(rest).forEach((value, i) => {
    if (value && value !== "") {
      values[Object.keys(rest)[i]] = value;
    }
  });
  let res;
  if (token) {
    res = await fetch({
      url: `users/${userId}`,
      method: "patch",
      data: values,
      headers: { Authorization: `Bearer ${token}` }
    });
  } else {
    res = await fetchAuth({
      url: `users/${userId}`,
      method: "patch",
      data: values
    });
  }

  return res.data;
};

export const getPasswordUserAPI = async (userId) => {
  const { data } = await fetchAuth({
    url: `users/${userId}/password`,
    method: "get"
  });
  return data;
};

export const deleteUserAPI = async (userId) => {
  const { data } = await fetchAuth({
    url: `users/${userId}`,
    method: "delete"
  });
  return data;
};

export const countUserAPI = async () => {
  const { data } = await fetchAuth({
    url: `users-count`,
    method: "get"
  });
  return data;
};

export const exportUsersAPI = async () => {
  const { data } = await fetchAuth({
    url: `users-export`,
    method: "get"
  });
  return data;
};


export const ReceiptsAPI = async () => {
  const { data } = await fetchAuth({
    url: `receipts-categories`,
    method: "get"
  });
  return data;
};

export const ReceiptCategoryUsersAPI = async () => {
  const { data } = await fetchAuth({
    url: `receipt-category-users`,
    method: "get"
  });
  return data;
};


export const downloadReceiptsAPI = async () => {
  const { data } = await fetchAuth({
    url: `download-receipts`,
    method: "get"
  });
  return data;
};

export const workFromHomeAPI = async () => {
  const { data } = await fetchAuth({
    url: `work-from-home-data`,
    method: "get"
  });
  return data;
};

export const vehicleLogbookAPI = async () => {
  const { data } = await fetchAuth({
    url: `vehicle-logbook`,
    method: "get"
  });
  return data;
};

export const listFeedbacksAPI = async (values) => {
  const _values = _.pickBy(values, _.identity);
  let _url = "feedback";
  Object.keys(_values).forEach((k, i) => {
    if (i === 0) {
      _url += `?${k}=${_values[k]}`;
    } else {
      _url += `&${k}=${_values[k]}`;
    }
  });
  const { data } = await fetchAuth({
    url: _url,
    method: "get"
  });
  return data;
};

export const sendNotificationAPI = async (values) => {
  const { data } = await fetchAuth({
    url: "send-notification",
    method: "post",
    data: values
  });
  return data;
}