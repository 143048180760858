import { createSlice } from "@reduxjs/toolkit";

export const worFromHomeSlice = createSlice({
  name: "workFromHome",
  initialState: {
    totalUsers: 0,
    taxYearEntries: {},
  },
  reducers: {
    countWorkFromHomeSuccess: (state, action) => {
      state.totalUsers = action.payload.totalUsers;
      state.taxYearEntries = action.payload.taxYearEntries;
    },
   
  }
});

// Action creators are generated for each case reducer function
export const {
  countWorkFromHomeSuccess
} = worFromHomeSlice.actions;

export default worFromHomeSlice.reducer;
