export const ROUTE_PATH = {
  HOME: "/",
  LOGIN: "/login",
  EDIT_USER: "/edit-user",
  VERIFY: "/verify",
  TERM_AND_CONDITION: "/terms-and-conditions",
  FEEDBACKS: "/feedbacks",
  USERS: "/users",
  NOTIFY_USERS: "/notify-users",
  FEEDBACK_DETAILS: "/feedback-details",
};

export const NOTIFY_TYPE = {
  ERROR: "error",
  SUCCESS: "success",
  WARNING: "warning"
};

export const USER_STATUS = {
  UNVERIFIED: "unverified",
  VERIFIED: "verified",
  SUSPENDED: "suspended"
};

export const RECEIPT_CATEGORY= {
  CAR: "Car",
  TRAVEL: "Travel",
  OTHER:"Other",
  EDUCATION:"Education",
  HOME_OFFICE:"Home Office",
  OFFICE_SUPPLIES:"Office Supplies",
  CUSTOM_CATEGORY: 'Custom Category'
};

export const WORK_FROM_HOME ={
  TOTAL_USERS: "Total users",
  TAX_YEAR_2024: "2023-24",
  TAX_YEAR_2025: "2024-25"
}

export const VEHICLE_LOGBOOK ={
  TOTAL_USERS: "Total users",
  TOTAL_VEHICLES: "Tax vehicles",
  TOTAL_TRIPS: "Total trips",
  TOTAL_BUSINESS_TRIPS: "Business trips",
  TOTAL_PRIVATE_TRIPS: "Private trips",
}
