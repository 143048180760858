import { createSlice } from "@reduxjs/toolkit";

export const vehicleLogbookSlice = createSlice({
  name: "vehicleLogbook",
  initialState: {
      totalVehicles:0,
      totalTrips:0,
      totalUsers:0,
      privateTrips:0,
      businessTrips:0
  },
  reducers: {
    countVehicleLogbookSuccess: (state, action) => {
      state.totalUsers = action.payload.totalUsers;
      state.totalVehicles = action.payload.totalVehicles;
      state.totalTrips = action.payload.totalTrips;
      state.privateTrips = action.payload.privateTrips;
      state.businessTrips = action.payload.businessTrips;
    },
   
  }
});

// Action creators are generated for each case reducer function
export const {
  countVehicleLogbookSuccess
} = vehicleLogbookSlice.actions;

export default vehicleLogbookSlice.reducer;
