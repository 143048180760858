import { createSlice } from "@reduxjs/toolkit";

export const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    isSend: false,
  },
  reducers: {
    sendNotificationSuccess: (state, action) => {
      state.isSend = true
    }
  }
});

export const {
  sendNotificationSuccess,
} = notificationSlice.actions;

export default notificationSlice.reducer;
