import { lazy } from "react";
import { ROUTE_PATH } from "../constants";

const LoginPage = lazy(() => import("../pages/LoginPage"));
const EditUserPage = lazy(() => import("../pages/EditUserPage"));
const ManagementUserPage = lazy(() => import("../pages/ManagementUserPage"));
const VerifyPage = lazy(() => import("../pages/VerifyPage"));
const TermAndConditionPage = lazy(() => import("../pages/TermAndConditionPage"));
const FeedbacksPage = lazy(() => import("../pages/FeedbacksPage"));
const HomePage = lazy(() => import("../pages/HomePage"));
const NotifyUsersPage = lazy(() => import("../pages/NotifyUsersPage"));
const FeedbackDetailsPage = lazy(() => import("../pages/FeedbackDetailsPage"));

const routerConfig = [
  {
    path: ROUTE_PATH.HOME,
    exact: true,
    requireAuth: true,
    component: HomePage
  },
  {
    path: ROUTE_PATH.LOGIN,
    exact: true,
    component: LoginPage,
    hasHeader: false,
    hasFooter: false,
  },
  {
    path: ROUTE_PATH.TERM_AND_CONDITION,
    exact: true,
    component: TermAndConditionPage,
    hasHeader: false,
    hasFooter: false,
  },
  {
    path: ROUTE_PATH.EDIT_USER,
    exact: true,
    component: EditUserPage,
    requireAuth: true,
    hasFooter: true,
  },
  {
    path: ROUTE_PATH.VERIFY,
    exact: true,
    component: VerifyPage,
    hasHeader: false,
    hasFooter: true,
  },
  {
    path: ROUTE_PATH.FEEDBACKS,
    exact: true,
    component: FeedbacksPage,
    hasHeader: true,
    hasFooter: true,
  },
  {
    path: ROUTE_PATH.USERS,
    exact: true,
    requireAuth: true,
    component: ManagementUserPage,
    hasFooter: true,
  },{
    path: ROUTE_PATH.NOTIFY_USERS,
    exact: true,
    requireAuth: true,
    component: NotifyUsersPage,
    hasFooter: true    
  },
  {
    path: ROUTE_PATH.FEEDBACK_DETAILS,
    exact: true,
    requireAuth: true,
    component: FeedbackDetailsPage,
    hasFooter: true    
  },
];

export default routerConfig;
